* {
  font-size: 24px;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
}

a {
  text-decoration: none !important;
  color: black !important;
}

.nav-link {
  color: white !important; 
}

select option {
  font-size: 0.75rem;
}

#loader-screen{ 
  height: 6vh;
}

.avatar {
  max-width: 400px !important;
}

#chatbox {
  background-color: #eee;
  height: 65vh;
  overflow-y: auto;
  flex-direction: column-reverse;
}

.err-text, .success-text {
  font-size: 0.75rem;
}

.footer {
  position: relative;
  bottom: 0;
}

.footer-text {
  font-size: 0.75rem;
}

#get-started-btn {
  height: 250px;
  border-radius: 25px;
}

.gif {
  max-height: 420px;
}

.hover-zoom {
  transition: transform 0.1s ease-in-out;
}

.hover-zoom:hover {
  transform: scale(1.3);
}

.justify {
  text-align: justify;
}

.lang-btn {
  border: 2px solid black !important;
}

.label-text {
  font-size: 0.8rem;
}

.messages p {
  font-family: 'Noto Sans SC', 'Noto Sans JP', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN', sans-serif;
}

#mic-btn {
  height: 85px;
}

.translate-btn {
  height: 50px;
}

/* RESPONSE LOADING ANIMATION */
:root {
  --main-color: #ecf0f1;
  --point-color: #555;
  --size: 5px;
}

.loader {
	background-color: var(--main-color);
	overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  top: 0; left: 0;
  display: flex;
	z-index: 10;
}

.loader__element {
  border-radius: 100%;
  border: var(--size) solid var(--point-color);
  margin: calc(var(--size)*2);
}

.loader__element:nth-child(1) {
  animation: preloader .6s ease-in-out alternate infinite;
}
.loader__element:nth-child(2) {
  animation: preloader .6s ease-in-out alternate .2s infinite;
}

.loader__element:nth-child(3) {
  animation: preloader .6s ease-in-out alternate .4s infinite;
}

@keyframes preloader {
  100% { transform: scale(2); }
}

.translate-loader {
  --point-color: #bbb;
}

@keyframes preloader {
  100% { transform: scale(2); }
}

@media screen and (min-width: 992px) {
  .nav-item {
    text-align: end !important;
  }
}

@media screen and (min-width: 1200px) {
  #script-chat-container {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}